import { lazy } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import { useAuthentication } from 'features/auth/context/AuthContext';
import { useWalletAuthentication } from 'features/trinsic-wallet/context/WalletAuthContext';

import ScrollToTop from './ScrollToTop';
import { Page, RoutesPrefixes } from './types';

const DisplayNFTPerCreator = lazy(
  () => import('features/displayNFTPerCreator/container/CreatorsNFTInfo')
);
const Footer = lazy(() => import('shared/Footer/Footer'));
const Page404 = lazy(() => import('features/misc/PageNotFound'));
const MyAssets = lazy(
  () => import('features/displayNFTs/container/MintedItems')
);
const SiteHeader = lazy(() => import('containers/SiteHeader'));
const NftDetailPage = lazy(
  () => import('features/nftDetail/container/NftDetailPage')
);

const VideoPageSearch = lazy(() => import('features/search/video/PageSearch'));
const CollectionPageSearch = lazy(
  () => import('features/search/collection/PageSearch')
);
const PageSearch = lazy(() => import('features/search/container/PageSearch'));

const HomePage = lazy(() => import('features/homepage/container/homepage'));
const CreateNFT = lazy(() => import('features/mintNewNFTs/container/Create'));
const NftMintingTypes = lazy(
  () => import('features/mintNewNFTs/container/NftMintingTypes')
);
const TrinsicAuthentication = lazy(
  () => import('features/trinsic-wallet/container/Authentication')
);
const WalletHomePage = lazy(
  () => import('features/trinsic-wallet/container/WalletHome')
);

const NFTsPerCollection = lazy(
  () => import('features/displayNFTPerCollection/container/DetailNftCollection')
);
const AdminSettingsPage = lazy(() => import('features/admin/container/index'));

const PrivateRoute = ({ children }) => {
  const { authenticated: isAuthenticated } = useAuthentication();
  return isAuthenticated && localStorage.chainId !== undefined ? (
    children
  ) : (
    <Navigate to='/' />
  );
};

const TrinsicProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useWalletAuthentication();
  return isAuthenticated ? children : <Navigate to='/trinsic/auth' />;
};
const {
  homePage,
  creatorsNFTPage,
  mintFreePage,
  mintOnChainPage,
  myAssetsPage,
  nftDetailPage,
  nftMintingTypePage,
  nftsPerCollectionPage,
  searchPage,
  ssiWalletAuth,
  ssiWalletHome,
  themeSettings,
} = RoutesPrefixes;
export const pages: Page[] = [
  { path: `${homePage}`, component: <HomePage /> },
  {
    path: `${nftDetailPage}/:chainId/:contractAddress/:tokenId/:owner/:ercType`,
    component: <NftDetailPage />,
  },
  {
    path: `${nftsPerCollectionPage}/:chainId/:contractAddress`,
    component: <NFTsPerCollection />,
  },
  { path: `${searchPage}/NFTs/:searchKey`, component: <PageSearch /> },
  { path: `${searchPage}/video`, component: <VideoPageSearch /> },
  { path: `${searchPage}/collection`, component: <CollectionPageSearch /> },

  { path: `${searchPage}/collection/:searchKey`, component: <PageSearch /> },
  { path: `${searchPage}`, component: <PageSearch /> },
  { path: `${myAssetsPage}`, component: <MyAssets /> },
  {
    path: `${creatorsNFTPage}/:ethAddress`,
    component: <DisplayNFTPerCreator />,
  },

  {
    path: `${mintFreePage}`,
    component: (
      <PrivateRoute>
        <CreateNFT />
      </PrivateRoute>
    ),
  },
  {
    path: `${mintOnChainPage}`,
    component: (
      <PrivateRoute>
        <CreateNFT />
      </PrivateRoute>
    ),
  },
  {
    path: `${nftMintingTypePage}`,
    component: (
      <PrivateRoute>
        <NftMintingTypes />
      </PrivateRoute>
    ),
  },
  {
    path: `${ssiWalletAuth}`,
    component: <TrinsicAuthentication />,
  },
  {
    path: `${ssiWalletHome}`,
    component: (
      <TrinsicProtectedRoute>
        <WalletHomePage />
      </TrinsicProtectedRoute>
    ),
  },
  {
    path: themeSettings,
    component: <AdminSettingsPage />,
  },
];

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
         {pages.map(({ component, path }) => {
          return (
            <Route
              key={path}
              element={
                path === '/theme-settings' ? (
                  component
                ) : (
                  <>
                    <SiteHeader />
                    {component}
                  </>
                )
              }
              path={path}
            />
          );
        })}
        <Route element={<Page404 />} path='*' />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default RoutesComponent;
